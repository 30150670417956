.header {
  height: 90px;
  padding: 0 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);

  img {
    height: 30px;
  }
}

@media only screen and (max-width: 1080px) {
  .header {
    height: 80px;
    padding: 0 16px;
  }
}