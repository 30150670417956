.Toastify__toast-container {
  width: 400px;
}

.Toastify__toast-container--bottom-right {
  right: 20px;
}

.Toastify__toast-container--top-center {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Toastify__toast {
  background: #363636 !important;
  padding: 10px;
  border-radius: 8px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__progress-bar--error {
  background: #FA5542;
}

.Toastify__progress-bar--success {
  background: #0AD5BD;
}

.toastBox {

  .message {
    margin: 6px 16px;
    font-size: 12px;

    .messageText {}
  }
}

.transactionToast {
  max-width: 100%;
  line-height: 20px;
  padding: 16px;

  .info {
    padding-bottom: 24px;
    border-bottom: 1px solid #5D5D5D;
  }
}