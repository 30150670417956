body {
  margin: 0;
  color: #eef2f9;
  font-size: 12px;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  line-height: 20px;
}

.App {
  width: 100vw;
  height: 100vh;
  background: url(./assets/images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.containerBox {
  width: 1400px;
  max-width: 100%;
}

.color1 {
  color: #eef2f9;
}

.color2 {
  color: #9b96ff;
}

.color3 {
  color: #ffd100;
}

.color4 {
  background: linear-gradient(180deg, #ffd100 0%, #f89542 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.color5 {
  color: rgba(238, 242, 249, 0.5);
}

.color6 {
  color: #d9d9d9;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-34 {
  margin-top: 34px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-24 {
  font-size: 24px;
  line-height: 36px;
}

.font-30 {
  font-size: 30px;
  line-height: 45px;
}

.font-36 {
  font-size: 36px;
  line-height: 54px;
}

.font-48 {
  font-size: 48px;
}

.font-weight-b {
  font-family: "Poppins-ExtraBold";
}

.font-weight-6 {
  font-family: "Poppins-Bold";
}

.font-weight-5 {
  font-family: "Poppins-Medium";
}

.font-weight-4 {
  font-family: "Poppins";
}

.font-weight-3 {
  font-family: "Poppins-Light";
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.border-t {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}

.border-l {
  border-left: 1px solid rgba(255, 255, 255, 0.16);
}

.flex-1 {
  flex: 1;
}
